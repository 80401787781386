import React, { Component } from "react";
import SouvenirsMV from './SouvenirsMV';

class Souvenirs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            DisplayMV: false,
            DisplayDt: false,
            InitVw: true
        }
    }

    display() {
        this.setState({
            SouvenirsMV: true,
            InitVw: false
        });
    }

    render() {
        return (
            <div id="page-flip" onLoad={(e) => this.display()}>
                {
                    this.state.InitVw ?
                        <div>
                            <img className="logo__init" width="200px" src="./img/logo.png" alt="logo" />
                            <div className="progress">
                                <div className="indeterminate"></div>
                            </div>
                        </div>
                        : null
                }

                {
                    this.state.SouvenirsMV ?
                        <SouvenirsMV />
                        : null
                }
            </div>
        );
    }
}

export default Souvenirs;
import React, { Component } from "react";

class Lista extends Component {
    render() {
        return (
            <div className="App-lista">
                <div className="cont-lista" style={{ cursor: 'pointer' }}>
                    <img src={this.props.imgurl} alt={this.props.imgalt}></img>
                </div>
                <span><p>{this.props.courses}</p></span>
            </div>
        );
    }
}

export default Lista;
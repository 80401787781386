import React, { Component } from "react";
import DisplayMV from './DisplayMv';
import DisplayDt from './DisplayDt';

class Contents extends Component {

    constructor(props) {
        super(props)
        this.state = {
            DisplayMV: false,
            DisplayDt: false,
            InitVw: true
        }
    }

    display() {

        let userMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        let userWin = navigator.platform.toUpperCase().indexOf('WIN') >= 0;
        let evaUser = (userMac || userWin);
        if (evaUser) {
            this.setState({
                DisplayMV: true,
                DisplayDt: false,
                InitVw: false
            });
        } else {
            this.setState({
                DisplayMV: true,
                DisplayDt: false,
                InitVw: false
            });
        }
    }


    render() {
        return (
            <div id="page-flip" onLoad={(e) => this.display()}>
                {
                    this.state.InitVw ?
                        <div>
                            <img className="logo__init" width="200px" src="./img/logo.png" alt="logo" />
                            <div className="progress">
                                <div className="indeterminate"></div>
                            </div>
                        </div>

                        : null
                }

                {
                    this.state.DisplayMV ?
                        <DisplayMV />
                        : null
                }
                {
                    this.state.DisplayDt ?
                        <DisplayDt />
                        : null
                }
            </div>
        );
    }
}

export default Contents;